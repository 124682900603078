@import 'variables.scss';

.match-bets-content {
  .match-bet-card-wrapper {
    position: relative;
    // height: 395px;
    height: 600px;


    width: 100%;
    max-width: 859px;
    margin-top: 1px;
    padding: 10px;
    padding-top: 0;
    // background: url('../../../imagesHold/match-bet-card.png');
    // background-repeat: repeat;

    // background-repeat: no-repeat;
    // background-size: cover;
    // background-position-x: center;
    .line {
      position: absolute;
      top: -1px;
      left: 0;
      width: 100%;
      height: 1px;
      background: linear-gradient(to right, #1d1922 0, #5e2830 30%, #581717 50%, #5f2830 70%, #1d1922 100%);
    }
  }
}
