@import 'variables.scss';

.amount-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  .amount {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 35px;
    width: 360px;
    background: linear-gradient(to right, #2a2a34, #23232b 50%, #161616);
    margin-bottom: 1px;
    padding: 0 10px 0 8px;
    .text {
      color: white;
      font-family: NotoSansCJKkr-Regular;
      font-size: 14px;
      margin-top: -2px;
    }
    .number {
      color: #e9ca4e;
      font-size: 16px;
      font-family: robotoMedium;
      margin-top: -2px;
      &.blue {
        color: #4195c1;
      }
      &.green {
        color: #00ff05;
      }
    }
    .input-wrapper {
      width: 222px;
      height: 31px;
      border-radius: 3px;
      background-color: black;
      display: flex;
      align-items: center;
      justify-content: space-between;
      button {
        width: 29px;
        height: 29px;
        margin-left: 1px;
        &:hover {
          filter: brightness(115%);
        }
      }
      .number {
        background-color: black;
        text-align: right;
        width: 190px;
        padding-right: 8px;
        height: 100%;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }

      // hide the up/down arrow of the number input
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
      }
      input[type=number] {
          -moz-appearance:textfield;
      }
    }
  }
  .amount-details {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 36px;
    width: 360px;
    background: linear-gradient(to right, #1a1a20, #18181b 50%, #161616);
    // margin-bottom: 1px;
    padding: 0 10px 0 8px;
    .text {
      color: #909090;
      font-family: NotoSansCJKkr-Regular;
      font-size: 14px;
      margin-top: 4px;
    }
    .number {
      color: #e5b877;
      font-size: 16px;
      font-family: Roboto-Regular;
    }
    .line {
      position: absolute;
      bottom: 0;
      left: 0;
      margin: 0 10px 0 8px;
      height: 1px;
      width: calc(100% - 18px);
      background: linear-gradient(to right, #35353a, #1d1d1d);
    }
  }

  .amount-buttons {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 8px;
    padding-bottom: 5px;
    padding-left: 9px;
    background-color: #19191e;
    margin-bottom: 1px;
    button {
      width: 112px;
      height: 34px;
      margin-bottom: 4px;
      padding-bottom: 2px;
      padding-left: 1px;
      background: url('../../../imagesHold/input-button.png');
      background-repeat: round;
      color: #e5b877;
      font-family: NotoSansCJKkr-Regular;
      font-size: 14px;
      &:nth-child(2), &:nth-child(5) {
        margin: 0 3px;
      }
      &.active {
        background: url('../../../imagesHold/input-button-gold.png');
        background-repeat: round;
        color: black;
        font-family: NotoSansCJKkr-Medium;
        &:hover {
          filter: brightness(115%);
        }
      }
      &:hover {
        filter: brightness(130%);
      }
    }
  }

  .action-buttons {
    display: flex;
    width: 100%;
    height: 60px;
    padding: 10px;
    padding-bottom: 12px;
    border-bottom: 10px;
    background-color: #18181b;
    color: black;
    font-family: NotoSansCJKkr-Regular;
    font-size: 14px;
    p {
      margin-bottom: 1px;
    }
    button {
      width: 165px;
      height: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: url('../../../imagesHold/input-increase-button.png');
      background-repeat: round;
      font-family: NotoSansCJKkr-Medium;
      &:hover {
        filter: brightness(115%);
      }
      .img-wrapper {
        margin-right: 5px;
      }
      &:nth-child(1) {
        position: relative;
        margin-right: 10px;
        background: linear-gradient(to bottom, #303035, #323236 50%, #36363a);
        box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.75);
        border-radius: 3px;
        color: #909090;
        &:hover {
          filter: brightness(130%);
        }
        .img-wrapper {
          margin-right: 7px;
        }
        .line {
          position: absolute;
          top: 0;
          left: 3px;
          height: 1px;
          width: calc(100% - 6px);
          background: linear-gradient(to right, #323237, #6d6d71 50%, #323237);
        }
      }
    }
  }
}
