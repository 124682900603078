@import 'variables.scss';

@mixin active-side-button {
  &.red-card:not(.favorite-card) {
    background: linear-gradient(to bottom, #e3a99e, #db8182 50%, #c55758);
  
    .side-button-wrapper {
        background-color: #e65757;
        text-shadow: 0.4px 0.4px 4.6px rgb(255 237 154 / 90%), -0.4px -0.4px 9.6px rgb(255 237 154 / 90%), 0.4px -0.4px 9.6px rgb(255 237 154 / 90%), -0.4px 0.4px 9.6px rgb(255 237 154 / 90%);
    
        .plus {}
    
        .number {}
    }
  
    .line {
      background: linear-gradient(to right, #ca9289, #f1b1a6);
    }
  }
  &:not(.favorite-card) &:not(.blue-card) {
    background: linear-gradient(to bottom, #e3a99e, #db8182 50%, #c55758);
  
    .side-button-wrapper {
        background-color: #e65757;
        text-shadow: 0.4px 0.4px 4.6px rgb(255 237 154 / 90%), -0.4px -0.4px 9.6px rgb(255 237 154 / 90%), 0.4px -0.4px 9.6px rgb(255 237 154 / 90%), -0.4px 0.4px 9.6px rgb(255 237 154 / 90%);
    
        .plus {}
    
        .number {}
    }
  
    .line {
      background: linear-gradient(to right, #ca9289, #f1b1a6);
    }
  }
}


.side-button {
  position: relative;
  width: 43px;
  height: 145px;
  padding: 1px;
  background: linear-gradient(to bottom, #9b6763, #8e4a4d 50%, #83393a);
  // box-shadow: -5px 1px 16px 0px rgb(8 8 8 / 74%);

  .side-button-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: #9b2f30;
    font-family: Roboto;
    color: white;

    .plus {
      font-size: 36px;
      line-height: 37px;
      margin-top: -11px;
      font-weight: 600;
    }

    .number {
      font-size: 18px;
      line-height: 20px;
      margin-top: -1px;
      font-family: RobotoMedium;
    }
    .text {
      margin-top: 25px;
    }
  }

  .line {
    position: absolute;
    width: 100%;
    height: 1px;
    top: 0;
    left: 0;
    background: linear-gradient(to right, #885352, #cd8a86);
  }

  .left-shadow {
    position: absolute;
    top: 0;
    left: -31px;
    height: 100%;
    width: 31px;
    // background: url('../../../../imagesHold/cards/side-button-shadow.png') no-repeat
    background: linear-gradient(to left, rgb(0 0 0 / 35%), rgb(0 0 0 / 28%) 20%, rgb(0 0 0 / 15%) 40%, rgba(0, 0, 0, 0));
  }

  &.active,
  &:hover {
    @include active-side-button;
  }

  &.blue-card {
    width: 43px;
    height: 110px;
    background: url('../../../../imagesHold/cards/live_card_blue.png');
    background-repeat: round;

    .side-button-wrapper {
      background-color: initial;
      .text {
        margin-top: 0;
        font-size: 14px;
        font-family: NotoSansCJKkr-Regular;
      }
    }

    &.favorite-card {
      .text {
        margin-top: 25px;
      }
    }

    .line {
      display: none;
    }

    &:not(.favorite-card) {
      &.active,
      &:hover {
        background: url('../../../../imagesHold/cards/live_card_blue_active.png');
        background-repeat: round;
      }
    }
  }
}

.favorite-card:not(.blue-card) {
  padding: 0;
  .side-button-wrapper {
    justify-content: flex-start;
    .up-arrow-wrapper {
      position: relative;
      width: 100%;
      height: 40px;
      background: url('../../../../imagesHold/cards/new/red-arow-inactive.png');
      background-repeat: round;

      &.active,
      &:hover {
        background: url('../../../../imagesHold/cards/new/red-arow-active.png');
      }

      .up-arrow {
        width: 100%;
        height: 40px;
        padding-bottom: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .arrow-line1 {
        position: absolute;
        bottom: -1px;
        left: -1px;
        width: calc(100% + 2px);
        height: 1px;
        background-color: #6f2f2d;
      }

      .arrow-line2 {
        position: absolute;
        bottom: -2px;
        left: -1px;
        width: calc(100% + 2px);
        height: 1px;
        background: linear-gradient(to right, #cd8a86, #b37271 50%, #9c5d5e);
      }
    }

    .favorite-plus {
      background: url('../../../../imagesHold/cards/new/red-plus-inactive.png');
      background-repeat: round;
      height: 105px;
      .plus {
        margin-top: 17px;
      }
      &.active,
      &:hover {
        background: url('../../../../imagesHold/cards/new/red-plus-active.png');
        text-shadow: 0.4px 0.4px 4.6px rgb(255 237 154 / 90%), -0.4px -0.4px 9.6px rgb(255 237 154 / 90%), 0.4px -0.4px 9.6px rgb(255 237 154 / 90%), -0.4px 0.4px 9.6px rgb(255 237 154 / 90%);
      }
    }

    .plus {
      margin-top: 19px;
    }
    .number {
      margin-top: 0;
    }
  }

  &.active,
  &:hover {


    &:not(.favorite-card) {
      .side-button-wrapper {
        .up-arrow-wrapper {
          .arrow-line1 {
            background-color: #c77f7e;
          }
  
          .arrow-line2 {
            background: linear-gradient(to right, #ffcbc8, #ffb2b0 50%, #ff9998);
          }
        }
      }
    }
    &.blue-card &:not(.favorite-card) {
      .side-button-wrapper {
        .up-arrow-wrapper {
          .arrow-line1 {
            background-color: #457f96;
          }

          .arrow-line2 {
            background: linear-gradient(to right, #6bbbd8, #68aedb 50%, #5d95c9);
          }
        }
      }
    }
  }


  &.blue-card &:not(.favorite-card) {
    .side-button-wrapper {
      .up-arrow-wrapper {
        .arrow-line1 {
          background-color: #284d67;
        }

        .arrow-line2 {
          background: linear-gradient(to right, #4e96c1, #4285bb 50%, #42698a);
        }
      }
    }
  }
  &.blue-card {
    .side-button-wrapper {
      .up-arrow-wrapper {
        .arrow-line1 {
          background-color: #284d67;
        }
        .arrow-line2 {
          background: linear-gradient(to right, #4e96c1, #4285bb 50%, #42698a);
        }
      }
    }
  }
}

.favorite-card:not(.red-card) {
  padding: 0;
  .side-button-wrapper {
    justify-content: flex-start;
    .up-arrow-wrapper {
      position: relative;
      width: 100%;
      height: 40px;
      background: url('../../../../imagesHold/cards/new/blue-arow-inactive.png');
      background-repeat: round;

      &.active,
      &:hover {
        background: url('../../../../imagesHold/cards/new/blue-arow-active.png');
      }

      .up-arrow {
        width: 100%;
        height: 40px;
        padding-bottom: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .arrow-line1 {
        position: absolute;
        bottom: -1px;
        left: -1px;
        width: calc(100% + 2px);
        height: 1px;
        background-color: #6f2f2d;
      }

      .arrow-line2 {
        position: absolute;
        bottom: -2px;
        left: -1px;
        width: calc(100% + 2px);
        height: 1px;
        background: linear-gradient(to right, #cd8a86, #b37271 50%, #9c5d5e);
      }
    }

    .favorite-plus {
      background: url('../../../../imagesHold/cards/new/blue-plus-inactive.png');
      background-repeat: round;
      height: 105px;
      .plus {
        margin-top: 16px;
      }
      &.active,
      &:hover {
        background: url('../../../../imagesHold/cards/new/blue-plus-active.png');
        text-shadow: 0.4px 0.4px 4.6px rgb(255 237 154 / 90%), -0.4px -0.4px 9.6px rgb(255 237 154 / 90%), 0.4px -0.4px 9.6px rgb(255 237 154 / 90%), -0.4px 0.4px 9.6px rgb(255 237 154 / 90%);
      }
    }

    .plus {
      margin-top: 19px;
    }
    .number {
      margin-top: 0;
    }
  }

  &.active,
  &:hover {


    &:not(.favorite-card) {
      .side-button-wrapper {
        .up-arrow-wrapper {
          .arrow-line1 {
            background-color: #c77f7e;
          }
  
          .arrow-line2 {
            background: linear-gradient(to right, #ffcbc8, #ffb2b0 50%, #ff9998);
          }
        }
      }
    }
    &.blue-card &:not(.favorite-card) {
      .side-button-wrapper {
        .up-arrow-wrapper {
          .arrow-line1 {
            background-color: #457f96;
          }

          .arrow-line2 {
            background: linear-gradient(to right, #6bbbd8, #68aedb 50%, #5d95c9);
          }
        }
      }
    }
  }


  &.blue-card &:not(.favorite-card) {
    .side-button-wrapper {
      .up-arrow-wrapper {
        .arrow-line1 {
          background-color: #284d67;
        }

        .arrow-line2 {
          background: linear-gradient(to right, #4e96c1, #4285bb 50%, #42698a);
        }
      }
    }
  }
  &.blue-card {
    .side-button-wrapper {
      .up-arrow-wrapper {
        .arrow-line1 {
          background-color: #284d67;
        }
        .arrow-line2 {
          background: linear-gradient(to right, #4e96c1, #4285bb 50%, #42698a);
        }
      }
    }
  }
}
