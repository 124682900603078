@import 'variables.scss';

@keyframes redUpAnimation {
  0% {
    bottom: 33.5px;
    left: 19px;
  }
  50% {
    bottom: 36.5px;
    left: 19px;
  }
  100% {
    bottom: 33.5px;
    left: 19px;
  }
}

@keyframes blueDownAnimation {
  0% {
    top: 32.5px;
    left: 19px;
  }
  50% {
    top: 35.5px;
    left: 19px;
  }
  100% {
    top: 32.5px;
    left: 19px;
  }
}

.match-details-wrapper {

  .match-details-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 54px;
    .left {
      z-index: 1;
      display: flex;
      align-items: center;
      margin-top: 5px;
      .square {
        width: 4px;
        height: 4px;
        background-color: #afb3be;
        margin-right: 10px;
      }
      .text {
        color: #d1cecf;
        font-size: 14px;
      }
    }
    button {
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 28px;
      height: 28px;
      border-radius: 3px;
      margin-top: 5px;
      background: url("../../../../imagesHold/new-button-bg.png");
      background-repeat: round;
      &:hover {
        filter: brightness(125%);
      }
      .rotate {
        transform: rotate(180deg);
      }
    }
  }

  .match-details-content {
    display: flex;
    flex-wrap: wrap;
    .result-row {
      display: flex;
      width: 100%;
      height: 36px;
    }
    .match-results-wrapper {
      cursor: pointer;
      position: relative;
      display: flex;
      width: 100%;
      height: 35px;
      background-color: rgb(255, 255, 255, 0.102);
      color: #d1cecf;
     
      .left {
        height: 35px;
        width: 345px;
        display: flex;
        justify-content: space-between;
        clip-path: polygon(0 0, 96.4% 0, 99.3% 100%, 0% 100%);
        align-items: center;
        .text {
          margin-left: 9px;
          font-size: 14px;
          font-family: NotoSansCJKkr-Regular;
        }
        &:hover {
          background: linear-gradient(to right, #9b2f30, #9b2f30 25%, #9a2f30 50%, #872d2f 63%, #722a2e 75%, #5d282e);
        }
        &.active {
          background: linear-gradient(to right, #9b2f30, #9b2f30 25%, #9a2f30 50%, #872d2f 63%, #722a2e 75%, #5d282e);
        }
      }

      .number1 {
        font-family: robotoMedium;
        font-size: 14px;
        padding-right: 21px;
        position: absolute;
        left: 292px;
        top: 8px;
        .number-value {
        
            position: absolute;
            left: -6px;
            width: 41px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &.red-arrow {
          img {
            position: absolute;
            bottom: 35.5px;
            left: 19px;
            animation: redUpAnimation 1s infinite;
          }
          .number-value {
            position: absolute;
            top: 19px;
            left: 12px;
            width: 46px;
            display: flex;
            justify-content: center;
            align-items: center;

          }
        }
      }
      .red-arrow {
        background: url("../../../../imagesHold/cards/red-up-arrow.png");
        background-repeat: round;
        width: 71px;
        height: 57px;
        margin-right: 2px;
        left: 271px;
        top: -11px;
        z-index: 5;
      }

      .right {
        height: 35px;
        width: 346px;
        // margin-left: -13px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        clip-path: polygon(3% 0%, 100% 0, 100% 100%, 0% 100%);
        margin-left: -14px;
        .text {
          margin-right: 8px;
          font-size: 14px;
          font-family: NotoSansCJKkr-Regular;
        }
        &:hover {
          background: linear-gradient(to left, #9b2f30, #9b2f30 25%, #9a2f30 50%, #872d2f 63%, #722a2e 75%, #5d282e);
        }
        &.active {
          background: linear-gradient(to left, #9b2f30, #9b2f30 25%, #9a2f30 50%, #872d2f 63%, #722a2e 75%, #5d282e);
        }
      }
      .blue-arrow {
        background: url("../../../../imagesHold/cards/blue-down-arrow.png");
        background-repeat: round;
        width: 71px;
        height: 57px;
        margin-right: 2px;
        left: 493px;
        top: -11px;
        z-index: 5;
      }
      .number2 {
        font-family: robotoMedium;
        font-size: 14px;
        margin-left: 7px;
        padding-left: 15px;
        position: absolute;

        .number-value {
            position: absolute;
            left: 3px;
            width: 47px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &:not(.blue-arrow) {
          z-index: 1;
          left: 496px;
          top: 8px;
        }

        &.blue-arrow {
          margin-left: 1px;
          img {
            position: absolute;
            top: 35.5px;
            left: 19px;
            animation: blueDownAnimation 1s infinite;
          }
          .number-value {
            position: absolute;
            top: 19px;
            left: 12px;
            width: 47px;
            display: flex;
            justify-content: center;
          }
        }
      }
    
      .middle {
        position: relative;
        width: 179px;
        height: 35px;
        padding: 0 1px;
        margin-left: -15px;
        clip-path: polygon(0 0, 100% 0, 94% 100%, 6% 100%);
        z-index: 2;
        .left {
          background: linear-gradient(to top, rgb(255 255 255 / 22%), rgb(255 255 255 / 9%) 50%, rgba(255, 255, 255, 0));
          width: 1.5px;
          height: 40px;
          position: absolute;
          left: 5.6px;
          top: -1px;
          transform: rotate(-16.5deg);
          z-index: 10;
        }
        

        .right {
          background: linear-gradient(to top, rgb(255 255 255 / 22%), rgb(255 255 255 / 9%) 50%, rgba(255, 255, 255, 0));
          width: 1.5px;
          height: 40px;
          position: absolute;
          right: 6.5px;
          top: -1px;
          transform: rotate(16.5deg);
          z-index: 10;
        }
        .middle-content {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 174px;
          margin-left: 0.5px;
          height: 35px;
          padding-left: 0;
          padding-top: 1px;
          clip-path: polygon(0 0, 100% 0, 94% 100%, 6% 100%);
          font-family: robotoMedium;
          font-size: 14px;
          &:hover {
            background-color: #9b2f30;
          }
          &.active {
            background-color: #9b2f30;
          }
        }
      }
    }
  }
}





