/* .App {
  text-align: center;
  font-family: "spoqa_han_sans_neo";
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

*:focus {
  outline: none;
}


html {
  width: 1920px;
  background-color: black;
  overflow: scroll;
  font-family: NotoSansCJKkr-Regular;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

html::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
html {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


@media only screen and (min-width: 1921px) {
  body {
    width: 100vw;
  }
  #root {
    width: 1920px;
    margin: auto;
  }
}

@media only screen and (max-width: 1921px) {
  .bet-page-cart {
    right: 0;
    background-color: black;
    z-index: 28;
  }
  .bet-page-cart:not(.popup-open) {
    position: fixed;
  }
}