@import 'variables.scss';

.cart {
  height: 100vh;
  overflow-y: scroll;
  color: white;
  width: 360px;
  .cart-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .cart-header-wrapper {
      margin-bottom: 1px;
      height: 40px;
      width: 360px;
      background: linear-gradient(180deg, #4d4d5c 0, #3c3c47 50%, #2d2d35 100%);
      .cart-header-content {
        height: 40px;

        .line {
          height: 1px;
          background: linear-gradient(90deg, rgba(255, 255, 255, 0.0) 0, rgba(255, 255, 255, 0.28) 49.87793%, rgba(255, 255, 255, 0.28) 50%, rgba(255, 255, 255, 0.0) 100%), rgba(44, 44, 52, 0.4);
        }

        .content {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-left: 10px;

          div {
            display: flex;
            align-items: center;
            // width: 50%;
            img {
              margin: 0;
              margin-right: 1px;
              &.cart-icon {
                margin-bottom: 1px;
                margin-right: 0;
              }
            }
          }

          .clock {
            display: flex;
            flex-direction: column;
            margin-right: 10px;
            font-size: 12px;
            margin-bottom: 3px;
            .date {
              color: #909090;
              width: 100%;
            }
            .time {
              display: flex;
              justify-content: flex-end;
              margin-top: -4px;
              color: #e5b877;
              width: 100%;
              img {
                margin-top: 1px;
              }
            }
          }

          img {
            margin-bottom: 1px;
          }

          .text {
            margin: 0 10px;
            font-family: RobotoMedium, sans-serif;
            font-size: 14px;
            text-transform: uppercase;
          }
          .circle {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 19px;
            width: 19px;
            border-radius: 50%;
            background: #f15325;
            font-family: Roboto;
            font-size: 12px;
            font-weight: 600;
            margin-bottom: 1px;
            p {
              margin-bottom: 1px;
            }
          }
        }
      }
    }

    .my-bets-wrapper {
      margin-bottom: 1px;
      margin-top: 10px;
      height: 40px;
      width: 360px;
      background: linear-gradient(180deg, #4d4d5c 0, #3c3c47 50%, #2d2d35 100%);

      .bets-content {
        height: 40px;

        .line {
          height: 1px;
          background: linear-gradient(90deg, rgba(255, 255, 255, 0.0) 0, rgba(255, 255, 255, 0.28) 49.87793%, rgba(255, 255, 255, 0.28) 50%, rgba(255, 255, 255, 0.0) 100%), rgba(44, 44, 52, 0.4);
        }

        .content {
          height: 100%;
          display: flex;
          align-items: center;
          margin-left: 10px;
          justify-content: space-between;

          >div {
            display: flex;
            align-items: center;
          }

          .refresh {
            button {
              background: url('../../imagesHold/checks-button.png');
              background-repeat: round;
              color: black;
              width: 65px;
              height: 32px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 9px;
              &:hover {
                filter: brightness(115%);
              }
              p {
                margin-right: 4px;
                margin-top: -5px;
              }
              .image-wrapper {
                margin-bottom: 3px;
              }
            }
          }

          .text {
            margin: 0 10px;
            font-family: RobotoMedium, sans-serif;
            font-size: 14px;
            color: white;
            font-weight: 600;
          }
        }
      }
    }

    .id-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 35px;
      margin-bottom: 1px;
      padding: 0 9px;
      background: linear-gradient(to right, #2a2a34 0, #212129 50%, #1a1a20 100%);
      .left {
        font-size: 14px;
        span {
          color: #e9ca4e;
        }
      }
      .right {
        font-family: Roboto, sans-serif;
        font-size: 12px;
        color: #909090;
        font-weight: 600;
        margin-bottom: 1px;
      }
    }

    .my-bet-card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 9px;
      padding-right: 9px;
      min-height: 60px;
      margin-bottom: 1px;
      font-size: 14px;
      background: url('../../imagesHold/mybet-card.png'); 
      background-repeat: round;
      background-size: cover;
      padding-top: 7px;
      padding-bottom: 7px;
      .title {
        margin-bottom: 3px;
        span {
          color: #c49f58;
        }
      }
      .result {
        color: #e5b877;
        span {
          display: inline-block;
          margin-left: 4px;
          color: #4195c1;
        }
      }
    }

    .my-bet-card-multi {
      background: url('../../imagesHold/my-bet-card-multi.png');
      background-repeat: round;
      width: 360px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #909090;
      font-size: 14px;
    }

    // TODO: this should removed
    .amount-details {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 36px;
      width: 360px;
      background: linear-gradient(to right, #1a1a20, #18181b 50%, #161616);
      // margin-bottom: 1px;
      padding: 0 10px 0 8px;
      .text {
        color: #909090;
        font-family: NotoSansCJKkr-Regular;
        font-size: 14px;
        margin-top: -3px;
      }
      .number {
        color: #e5b877;
        font-size: 14px;
        font-family: Roboto-Regular;
        display: flex;
        margin-top: -2px;
        p {
          color: wheat;
          margin-right: 10px;
        }
      }
      .line {
        position: absolute;
        bottom: 0;
        left: 0;
        margin: 0 10px 0 8px;
        height: 1px;
        width: calc(100% - 18px);
        background: linear-gradient(to right, #35353a, #1d1d1d);
      }
    }

    .see-all-button {
      height: 77px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(to right, #1a1a20, #18181b 50%, #161616);

      button {
        width: 344px;
        margin-top: 6px;
        height: 63px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: url('../../imagesHold/see-all-button.png');
        background-repeat: round;
        padding-bottom: 6px;
        &:hover {
          filter: brightness(115%);
        }
        p {
          margin-left: 11px;
          margin-bottom: 2px;
          color: black;
          font-family: NotoSansCJKkr-Medium;
        }
      }
    }
  }
}
