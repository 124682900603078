@import "variables.scss";

@mixin active-body {
    background: url("../../../../imagesHold/cards/red-body-active.png");

    &.blue {
        background: url("../../../../imagesHold/cards/blue-body-active.png");
        .left {
            .star {
                padding-bottom: 0;
            }
            .teams-wrapper {
                .team-2 {
                    margin-top: -6px;
                }
            }
        }
        .middle {
            .button-wrapper {
                button {
                    margin-top: 14px;
                }
            }
        }
    }
}

.card-body-wrapper {
    background: url("../../../../imagesHold/cards/red-body.png");
    background-repeat: round;
    width: 100%;
    height: 73px;
    color: white;
    display: flex;

    .left {
        width: 40%;
        height: 100%;
        display: flex;

        .star {
            display: flex;
            align-items: center;
            margin-left: 10px;
            margin-right: 12px;
            padding-bottom: 3px;
            width: 18px;
        }
        .teams-wrapper {
            display: flex;
            flex-direction: column;

            // padding: 14px 0;
            .team-1 {
                height: 50%;
                display: flex;
                align-items: center;
                margin-top: 2px;
            }

            .team-2 {
                height: 50%;
                display: flex;
                align-items: center;
                margin-top: -7px;

                .shirt,
                .bg-holder-26 {
                    margin-left: 1px;
                }
            }

            .team-1,
            .team-2 {
                .shirt {
                    margin-right: 7px;
                }

                .bg-holder-26 {
                    height: 11px;
                    width: 10px;
                    border-radius: 3px;
                    background: rgba(255, 255, 255, 0.3);
                    margin-right: 10px;

                    .h-3 {
                        height: 11px;
                        font-weight: 600;
                        color: #212121;
                        font-family: Roboto, sans-serif;
                        font-size: 10px;
                        letter-spacing: 0;
                        line-height: 11px;
                        text-align: center;
                        text-transform: uppercase;
                        margin-top: -1px;
                        width: 10px;
                    }
                }
            }
        }
    }

    .middle {
        width: 20%;

        .text {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-size: 14px;
            font-family: NotoSansCJKkr-Regular;
            margin-top: 0;
            margin-right: 4px;
            letter-spacing: -1px;
            text-shadow: 0.4px 0.4px 9.6px #ff0006, -0.4px -0.4px 9.6px #ff0006,
                0.4px -0.4px 3.6px #ff0006, -0.4px 0.4px 9.6px #ff0006;

            span {
                font-family: Roboto, sans-serif;
                font-size: 16px;
                margin-top: 1px;
            }
        }

        .button-wrapper {
            width: 100%;
            text-align: center;
            margin-top: -1px;

            button {
                margin-top: 11px;
                margin-right: 2px;
                .date {
                    font-family: Roboto, sans-serif;
                    font-size: 12px;
                    color: #78b7e5;
                    font-weight: 600;
                }
                &:hover {
                    filter: brightness(140%);
                }
            }
        }
    }

    .right {
        width: 40%;
        padding-right: 3px;
        margin-top: 8px;

        p {
            display: flex;
            justify-content: center;
        }

        .line {
            display: flex;
            justify-content: flex-end;
            font-family: RobotoMedium, sans-serif;
            font-size: 18px;
            font-weight: 500;
            text-align: end;

            p {
              width: 20px;
            }

            &:nth-of-type(2) {
                // margin-top: 2px;
            }

            .gold {
                color: #e9ca4e;
            }
            .second {
                // height: 18px;
                // width: 18px;
                // margin-left: 13px;
                // margin-right: 10px;
                // margin-right: 6px;
                margin-left: 0px;
            }

            .third {
                // height: 18px;
                // width: 18px;
                // margin-right: 13px;
                margin-left: 0px;
            }

            .forth {
                // margin-right: 9.5px;
                margin-right: -2px
            }

            .ball {
                // margin-left: 8px;
                // margin-right: 15px;
                width: 24px;
                letter-spacing: 0px;
                text-align: center;
                // min-width: 18px;
                // max-width: 17px;

            }
        }
    }

    &.active,
    &:hover {
        @include active-body;
    }

    &.blue {
        background: url("../../../../imagesHold/cards/blue-body.png");
        height: 70px;
        .left {
            .star {
                margin-bottom: 1px;
                padding-bottom: 0;
            }
            .teams-wrapper {
                .team-1 {
                    margin-top: 3px;
                }
                .team-2 {
                    margin-top: -6px !important;
                }
            }
        }
        .middle {
            .text {
                text-shadow: none;
                margin-top: 2px;
            }
            .button-wrapper {
                button {
                    margin-top: 14px;
                }
            }
        }
    }
}
