@charset "UTF-8";

.league-popup {
  z-index: 30;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.6);
  overflow: hidden;
  .league-popup-wrapper {
    height: 276px;
    left: 713px;
    position: absolute;
    top: 402px;
    width: 494px;
    z-index: 10;
    background: linear-gradient(to bottom, #303039, #303038 50%, #2d2d35);

    bottom: 0;
    left: 0;
    margin: auto;
    max-height: 500px;
    max-width: 600px;
    min-width: 300px;
    position: fixed;
    right: 0;
    top: 42px;
    margin-top: 260px;

    .header {
      position: relative;
      display: flex;
      height: 40px;
      justify-content: center;
      align-items: center;
      background: linear-gradient(to bottom, #4d4d5c, #3c3c47 50%, #2d2d35);
      .line-1 {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: linear-gradient(to left, rgba(255,255,255,0), rgb(255,255,255) 50%, rgba(255,255,255,0));
        opacity: 0.4;
      }
      .text {
        font-size: 15px;
        letter-spacing: 0;
        line-height: 16px;
        text-transform: uppercase;
        margin-top: 3px;
        color: white;
      }
      button {
        height: 41px;
        width: 41px;
        position: absolute;
        right: 0;
        top: 0;
        background: linear-gradient(to top, #545462, #6f6f82);
        border-left: 1px solid #000;
        display: flex;
        justify-content: flex-end;
        .button-content {
          display: flex;
          justify-content: center;
          align-items: center;
          width: calc(100% - 1px);
          height: 100%;
          background: linear-gradient(to bottom, #4d4d5c, #3c3c47 50%, #2d2d35);
          &:hover {
            opacity: 0.7;
          }
          img {
            width: 14px;
            height: 14px;
            margin-right: 1px;
          }
        }
      }
    }
    .main-live-league-bg-holder-3 {
      height: 177px;
      left: 0;
      position: absolute;
      top: 41px;
      width: 494px;
      background: linear-gradient(to right, #43434f, #373742 50%, #2a2a34);
      color: white;
      .main-live-league-col {
        height: 180px;
        left: 0;
        position: absolute;
        top: 0;
        width: 494px;
        border-top: 0.5px solid #000000;
        box-shadow: inset 1px -1px 5px 0px rgba(255,255,255,0.1);
        .main-live-league-group-4 {
          height: 16px;
          left: 17px;
          position: absolute;
          top: 17px;
          width: 128px;
          .main-live-league-chck {
            height: 16px;
            left: 0;
            position: absolute;
            top: 0;
            width: 16px;
            background: #000000;
            .main-live-league-chx-ck {
              left: 0;
              position: absolute;
              top: 2px;
            }
          }
          .main-live-league-text-2 {
            left: 26px;
            position: absolute;
            top: -3px;
            font-size: 15px;
            text-transform: uppercase;
          }
        }
        .main-live-league-group-5 {
          height: 102px;
          left: 39px;
          position: absolute;
          top: 50px;
          width: 383px;
          font-size: 14px;
          .main-live-league-col-2 {
            height: 102px;
            left: 0;
            position: absolute;
            top: 0;
            width: 170px;
          }
          .main-live-league-col-3 {
            height: 102px;
            left: 229px;
            position: absolute;
            top: 0;
            width: 170px;
          }
        }
      }
    }
    .main-live-league-bg-holder-4 {
      height: 57px;
      left: 0;
      position: absolute;
      top: 219px;
      width: 494px;
      background: linear-gradient(to right, #2a2a34, #22222a 50%, #1a1a20);
      .main-live-league-bg-holder-5 {
        height: 56px;
        left: 0;
        position: absolute;
        top: 0;
        width: 494px;
        border-top: 1px solid #000000; /*stroke*/
        .main-live-league-group-6 {
          height: 48px;
          left: 5.5px;
          position: absolute;
          top: 3px;
          width: 484px;
          .main-live-league-bg-holder-7 {
            height: 48px;
            left: 0;
            position: absolute;
            top: 0;
            width: 239px;
            border-radius: 2px;
            background: url('../../imagesHold/new-popup-btn.png');
            background-repeat: round;
            box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.75); /*drop shadow*/
            &:hover {
              filter: brightness(130%);
            }
            .main-live-league-text-11 {
              left: 103px;
              top: 0px;
              position: absolute;
              color: #909090;
              font-size: 18px;
              letter-spacing: 0;
              line-height: 48px;
              text-align: center;
              text-transform: uppercase;
            }
          }
          .main-live-league-wrapper-4 {
            height: 48px;
            left: 245px;
            position: absolute;
            top: 0;
            width: 239px;
            .main-live-league-bg-holder-10 {
              height: 48px;
              left: -1px;
              position: absolute;
              top: 0;
              width: 239px;
              border-radius: 2px;
              background: url('../../imagesHold/new-popup-btn-active.png');
              background-repeat: round;
              box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.75); /*drop shadow*/
              &:hover {
                filter: brightness(110%);
              }
              .main-live-league-text-12 {
                left: 104px;
                position: absolute;
                top: 0;
                color: #000000;
                font-size: 18px;
                letter-spacing: 0;
                line-height: 48px;
                text-align: center;
                text-transform: uppercase;
              }
            }
          }
        }
      }
    }
  }
}
