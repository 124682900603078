@import 'variables.scss';

@mixin list-active-button {
  position: relative;
  background: linear-gradient(to top, #a99365, #baa375);

  .fade {
    display: block;
    background: linear-gradient(to top, #efdfb894, #cbb37f00);
    height: 10px;
    width: calc(100% - 6px);
    position: absolute;
    bottom: 3px;
    left: 3px;
    z-index: 0;
  }

  .navbar-button-border {
    height: 100%;
    z-index: 2;
    background: linear-gradient(to top, #fefecb, #d3c49f 50%, #baa375);

    .navbar-button-inner {
      height: 100%;
      background: linear-gradient(to top, #d8bc6f, #aa8f60 50%, #d6c8a5);

      .navbar-button-content {
        color: black;

        .button-content-wrapper {
          img {
            filter: invert(1);
          }

          .number {
            color: #c8b273;
            margin-top: 2.5px;

            span {
              background-color: rgb(16, 16, 16);
            }
          }
        }
      }
    }
  }
}

@mixin layout-active-button {
  position: relative;
  background: linear-gradient(to top, #fefecb, #d3c49f 50%, #baa375);

  .layout-button-content {
    color: black;
    background: linear-gradient(to top, #d8bc6f, #aa8f60 50%, #d6c8a5);

    img {
      filter: brightness(0);
    }
  }

  .fade {
    display: block;
    background: linear-gradient(to top, #efdfb894, #cbb37f00);
    height: 10px;
    width: calc(100% - 2px);
    position: absolute;
    bottom: 1px;
    left: 1px;
    z-index: 0;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
  }
}

.navbar-wrapper {
  display: flex;
  flex-wrap: wrap;

  .navbar-list-wrapper {
    display: flex;
    margin-left: 8px;
    position: relative;

    .navbar-button {
      box-shadow: 0 3px 0 0 #351a27;
      transform: skew(-12.1deg);
      border: 0;
      border-right: 1px solid #000;
      height: 80px;
      width: 109.1px;
      padding: 2px;
      font-size: 14px;
      background: linear-gradient(to top, #202026, #3e3e49);

      .fade {
        display: none;
      }

      .navbar-button-border {
        height: 100%;
        padding: 1px;
        background: linear-gradient(to top, #4d4d5c, #3c3c47 50%, #3e3e49);

        .navbar-button-inner {
          height: 100%;
          background: linear-gradient(to top, #202026, #3e3e49);

          .navbar-button-content {
            height: 100%;
            color: #8a8a8d;
            transform: skew(12.1deg);
            text-align: center;

            .button-content-wrapper {
              padding-top: 7px;

              img {
                margin: auto;
                filter: invert(0.4);
              }

              p {
                font-family: NotoSansCJKkr-Medium;
                margin-top: 3px;

                &.english {
                  margin-top: 2px;
                }
              }

              .number {
                margin-top: 2px;

                &.english {
                  margin-top: 3px;
                }

                span {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin: auto;
                  font-size: 12px;
                  font-family: Roboto-Regular;
                  // width: 19px;
                  height: 13px;
                  border-radius: 3px;
                }
              }
            }
          }
        }
      }

      &.active,
      &:hover {
        @include list-active-button;
        &:nth-child(2), &:nth-child(3), &:nth-child(7), &:nth-child(9) {
          .navbar-button-border {
            .navbar-button-inner {
              .navbar-button-content {
                .button-content-wrapper {
                  .number {
                    margin-top: 2px;
                  }
                }
              }
            }
          }
        }
      }

      &:nth-child(1) {
        .navbar-button-border {
          .navbar-button-inner {
            .navbar-button-content {
              .button-content-wrapper {
                .number {
                  margin-top: 2px;
                  span {
                    padding-left: 1px;
                    padding-top: 1px;
                    padding-left: 3px;
                    padding-right: 3px;
                  }
                }
              }
            }
          }
        }
      }


    &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(7), &:nth-child(9) {
      .navbar-button-border {
        .navbar-button-inner {
          .navbar-button-content {
            .button-content-wrapper {
              .number {
                span {
                  padding-top: 1px;
                  padding-left: 3px;
                  padding-right: 3px;
                }
              }
            }
          }
        }
      }
    }

      &:nth-child(4) {
        .navbar-button-border {
          .navbar-button-inner {
            .navbar-button-content {
              .button-content-wrapper {
                .number {
                  margin-top: 2px;
                }
              }
            }
          }
        }
      }
      &:nth-child(5) {
        .navbar-button-border {
          .navbar-button-inner {
            .navbar-button-content {
              .button-content-wrapper {
                .number {
                  margin-top: 1px;
                  span {
                    padding-top: 1px;
                    padding-left: 3px;
                    padding-right: 3px;
                  }
                }
              }
            }
          }
        }
      }
      &:nth-child(6) {
        .navbar-button-border {
          .navbar-button-inner {
            .navbar-button-content {
              .button-content-wrapper {
                .number {
                  margin-top: 1px;
                  span {
                    padding-left: 1px;
                    padding-top: 1px;
                    padding-left: 3px;
                    padding-right: 3px;
                  }
                }
              }
            }
          }
        }
      }
      &:nth-child(8) {
        .navbar-button-border {
          .navbar-button-inner {
            .navbar-button-content {
              .button-content-wrapper {
                .number {
                  margin-top: 1px;
                  span {
                    padding-left: 1px;
                    padding-top: 1px;
                    padding-left: 3px;
                    padding-right: 3px;
                  }
                }
              }
            }
          }
        }
      }

      &:nth-child(10) {
        .navbar-button-border {
          .navbar-button-inner {
            .navbar-button-content {
              .button-content-wrapper {
                .number {
                  margin-top: 2px;
                  span {
                    padding-left: 1px;
                    padding-top: 1px;
                    padding-left: 3px;
                    padding-right: 3px;
                  }
                }
              }
            }
          }
        }
      }

      &:nth-child(7) {
        margin-left: -1px;
      }
    }
  }

  .select-layout-wrapper {
    margin-top: 6px;
    margin-left: 316px;
    margin-right: 10px;

    .select-layout-button {
      width: 64px;
      height: 73px;
      border-radius: 10px;
      background-color: rgb(44, 44, 52);
      background: linear-gradient(to top, #4d4d5c, #3c3c47 50%, #33333c);
      box-shadow: 0 3px 0 0 #351a27;
      padding: 1px;

      &:nth-child(2) {
        margin-left: 8px;
      }

      .layout-button-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 18px;
        color: #8a8a8d;
        background: linear-gradient(to top, #202026, #24242b 50%, #3d3d48);
        width: 100%;
        height: 100%;
        border-radius: inherit;

        p {
          margin-top: 5px;
          font-family: NotoSansCJKkr-Medium;
          font-size: 14px;
        }
      }

      .fade {
        display: none;
      }

      &.active,
      &:hover {
        @include layout-active-button
      }
    }
  }

  .live-matches-number {
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 9px;
    color: #f6f4f5;

    .red-icon {
      height: 6px;
      width: 6px;
      border-radius: 2.8px;
      background: #9b3031;
      margin-top: 3px;
    }

    .matches-number-content {
      display: flex;
      justify-content: center;
      align-items: center;

      .text {
        margin-left: 8px;
        margin-top: 4px;
        font-size: 13px;
        font-family: NotoSansCJKkr-Regular;
      }

      .number-wrapper {
        width: auto;
        padding: 0 7.5px;
        height: 21px;
        background-color: rgb(65, 65, 65);
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
        margin-top: 4px;

        .number {
          margin-top: 1px;
          font-size: 14px;
          font-family: Roboto-Regular;
        }
      }
    }

    .multi-view-buttons-wrapper {
      margin-left: 9px;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  .view-select-button {
    margin-right: 10px;
    display: flex;
    align-items: center;
  }
}