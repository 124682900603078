@import 'variables.scss';

.multi-view-cards-wrapper {
  width: 1550px;
  display: flex;
  overflow-x: scroll;
  // overflow-y: hidden;
  padding-bottom: 5px;
  margin-bottom: 5px;
  // position: sticky;
  top: 126px;
  z-index: 27;
  background: #222226;
  height: 121px;
  position: fixed;

  .multi-view-card-wrapper {
    cursor: move;
    padding-right: 1px;
    min-width: 385px;
    height: 111px;
    margin-right: 5px;
    background: url('../../imagesHold/multi/multi-view-card.png');
    background-repeat: round;

    .card-header {
      padding-left: 9px;
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: white;

      .left {
        font-size: 15px;
      }

      .right {
        width: 93px;
        display: flex;
        color: #d1cecf;

        .results-number {
          font-family: RobotoMedium;
          font-size: 18px;
          margin-right: 17px;

          &:nth-child(2) {
            margin-right: 17px;
          }
        }
      }
    }

    .card-body {
      color: white;

      .teams-wrapper {
        display: flex;
        // flex-direction: column;
        height: 71px;
        justify-content: space-between;
        align-items: center;

        // padding: 14px 0;
        .left {
          display: flex;
          // flex-direction: column;
          // align-items: flex-start;
          padding-top: 3px;
          height: 71px;

          .star {
            width: 39px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            padding-right: 1px;
            padding-bottom: 4px;
          }

          .team-1 {
            margin-left: 3px;
            height: 50%;
            display: flex;
            align-items: center;

            .text-54 {
              color: #d1cecf;
            }
          }

          .team-2 {
            height: 50%;
            display: flex;
            align-items: center;
            margin-top: -5px;
            margin-left: 4px;

            .text-55 {
              color: #d1cecf;
            }

            .shirt,
            .bg-holder-26 {
              margin-left: 1px;
            }
          }

          .team-1,
          .team-2 {
            .shirt {
              margin-right: 8px;
              margin-left: -3px;
            }

            .bg-holder-26 {
              height: 11px;
              width: 11px;
              flex-shrink: 0;
              border-radius: 3px;
              background: rgba(255, 255, 255, 0.3);
              margin-right: 10px;

              .h-3 {
                color: #212121;
                font-family: Roboto, sans-serif;
                font-size: 10px;
                letter-spacing: 0;
                line-height: 11px;
                text-align: center;
                text-transform: uppercase;
                font-weight: 600;
                margin-top: -1px;
              }
            }
          }

          .team-1 {
            .shirt {
              margin-right: 7px;

              img {
                margin-left: 1px;
              }
            }
          }

          .team-2 {
            .shirt {
              margin-bottom: 0px;
              margin-right: 7px;

              img {
                margin-left: 1px;
              }
            }

            .bg-holder-26 {
              margin-bottom: 0px;
            }
          }
        }

        .right {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 7px;
          height: 71px;
          font-family: RobotoMedium;
          font-size: 18px;

          .first-row,
          .second-row {
            width: 93px;
            display: flex;
          }

          .first-row {
            margin-top: 4px;
          }

          .second-row {
            margin-top: 2px;
          }

          .results-number {
            // margin-right: 18px;
            width: 20px;
            text-align: center;

            &:nth-child(2) {
              // margin-right: 26px;
            }
          }

          .result {
            color: #ffcc66;
            min-width: 28px;
            text-align: center;
            width: 30px;
          }
        }
      }
    }
  }

  &::-webkit-scrollbar {
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #666666;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #333333;
    border-radius: 5px;
  }
}

.multi-view-container {
  position: relative;
  padding-right: 14px;

  .multi-view-scroll {
    // height: 1080px;
    overflow-y: scroll;
    margin-top: 126px;
    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: #666666;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #333333;
      border-radius: 5px;
    }
  }
}