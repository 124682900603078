@import 'variables.scss';

.cart-cards-wrapper {
  width: 360px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  .cart-card-wrapper {
    padding: 7px 0 7px 11px;
    position: relative;
    min-height: 81px;
    margin-bottom: 1px;
    font-family: NotoSansCJKkr-Regular;
    font-size: 14px;
    color: white;
    background: url('../../../imagesHold/cart-card-bg.png');
    background-repeat: round;
    background-size: cover;
    .close {
      position: absolute;
      right: 10px;
      top: 10px;
      &:hover {
        filter: brightness(130%);
      }
    }
    .title {
      margin-top: 1px;
    }
    .content {
      margin-top: 2px;
    }
    .number {
      display: flex;
      align-items: center;
      margin-top: 2px;
      .strikethrough {
        display: inline-block;
        margin-right: 10px;
        color: #909090;
        text-decoration: line-through;
      }
    }
    .gold {
      color: #e5b877;
    }
    .gold-1 {
      color: #c49f58;
    }
    .blue {
      color: #4195c1;
      margin-right: 9px;
    }
  }
  .cart-card-wrapper-multi {
    width: 360px;
    height: 81px;
    background: url('../../../imagesHold/cart-card-multi.png');
    background-repeat: round;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #909090;
    font-size: 14px;
    margin-bottom: 1px;
  }
}