@import 'variables.scss';

.nav-view-button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background: linear-gradient(to top, #282828, #1c1c1c 50%, #0f0f0f);
    border: 2px solid #b89c5e;
    border-radius: 6px;
    width: 136px;
    height: 32px;
    margin-left: 9px;
    margin-top: 4px;

    p {
      font-family: NotoSansCJKkr-Regular;
      font-size: 16px;
      margin-left: 8px;
      margin-top: -3px;
    }

    
    &.active,
    &:hover {
      background: url('../../../imagesHold/fade.png') no-repeat, linear-gradient(to top, #5f4821, #55411e 50%, #49381b);
      img {
        filter: brightness(4);
      }
    }

    &.multi {
      width: 104px;
      margin-right: 6px;
      border: none;
      background: linear-gradient(to top, #282828, #1c1c1c 50%, #0f0f0f);
      border: 2px solid #7a7e8d;
      margin-top: 3px;
      margin-left: -1px;
      p {
        font-size: 14px;
        margin-left: 0;
        margin-top: 1px;
        color: #949494;
      }
      &.active,
      &:hover {
        background: url('../../../imagesHold/multi/multi-view-button.png');
        background-repeat: round;
        border: 0;
        p {
          color: white;
        }
      }
    }

  }