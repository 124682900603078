@import 'variables.scss';

.match-img-wrapper {
  display: flex;

  .aa-scroll {
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 5px;
      display: none;
    }
}
.match-scroll {
  overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 5px;
      display: none;
    }
}
  .match-img {
    margin: 0 10px;
    width: 863px;
    height: 518px;
    background: url("../../imagesHold/pic1.png") no-repeat;
  }

  .match-bets-wrapper {
    margin: 0 10px;
    // height: 445px;
    // overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: #666666;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #333333;
      border-radius: 5px;
    }
  }
}

.matches-count {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  height: 40px;
  width: 100%;
  margin-bottom: 9px;
  margin-top: 1px;
  padding-right: 42px;
  padding-bottom: 2px;
  background: url("../../imagesHold/matches-count.png");
  background-repeat: round;

  .left,
  .right {
    width: 50%;
    display: flex;
    align-items: center;
    height: 40px;
    color: #afb3be;
    margin-left: 18px;

    .text {
      font-size: 13px;
    }

    .number {
      padding: 0 4px;
      margin-top: -1px;
      min-width: 20px;
      height: 18px;
      border-radius: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-family: Roboto, sans-serif;
    }
  }

  .left {
    margin-right: 0;
    padding-right: 22px;
    margin-left: 0;
    justify-content: flex-end;

    .text {
      font-size: 13px;
    }

    .number {
      background-color: #932d2e;
      margin-left: 11px;
      color: white;
      font-weight: 600;
    }
  }

  .right {
    margin-left: 0;
    padding-left: 19px;
    .number {
      background-color: #225f8b;
      margin-right: 11px;
      color: white;
      font-weight: 600;
    }
  }
}
