@import 'variables.scss';

.multi-view-match-results-wrapper {
  display: flex;
  flex-wrap: wrap;
  // height: 740px;
  // overflow-y: scroll;
  // position: relative;
  //   top: 122px;

  .multi-view-match-result-wrapper {
    width: 510px;
    margin-right: 5px;
    margin-bottom: 5px;

    .multi-view-match-card-wrapper {
      width: 510px;
      height: 152px;
      background: url('../../imagesHold/multi-match-card.png');
      background-repeat: round;
      display: flex;
      flex-direction: column;

      .multi-card-header-content {
        display: flex;
        width: 100%;
        height: 40px;

        .left {
          display: flex;
          width: 70%;
          height: 100%;
          align-items: center;

          .ball {
            margin-left: 12px;
            margin-right: 12px;
          }

          .text {
            color: #c8b273;
            font-size: 15px;
            letter-spacing: 0;
            margin-left: 11px;
            text-transform: uppercase;
            white-space: nowrap;
            font-family: NotoSansCJKkr-Regular;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .right {
          display: flex;
          width: 31%;
          height: 100%;
          align-items: center;
          justify-content: flex-end;
          margin-top: 0px;

          button {
            position: relative;
            width: 49px;
            height: calc(100% - 1px);
            // margin-top: 3px;
            background-color: #4d1e22;
            display: flex;
            justify-content: center;
            align-items: center;

            .left-line-1 {
              position: absolute;
              left: 0;
              top: 0;
              height: 100%;
              width: 1px;
              background-color: #3a191d;
            }

            .left-line-2 {
              position: absolute;
              left: 1px;
              top: 0;
              height: 100%;
              width: 1px;
              background: linear-gradient(to bottom, #702b30, #62262b 50%, #4e1f23);
            }

            .fade {
              position: absolute;
              bottom: 0;
              left: 2px;
              width: 100%;
              height: 10px;
              background: url('../../imagesHold/m-card-fade.png');
              background-repeat: round;
            }

            &.close {
              margin-right: 1px;

              &:hover {
                filter: brightness(140%);
              }
            }


            &.active {
              // 7f2c30
              background: linear-gradient(to left, #7f2c30, #812c2f);

              .left-line-2 {
                background: linear-gradient(to bottom, #995b57, #794241 50%, #4f2024);
              }

              .line {
                position: absolute;
                // width: calc(100% - 1px);
                width: 100%;
                height: 1px;
                top: -1px;
                left: 1px;
                background: linear-gradient(to right, #ab615a, #9b5651 50%, #8b4c49);
              }
            }

            &:hover {
              filter: brightness(130%);
            }
          }
        }
      }









      .multi-card-sub-header-content {
        display: flex;
        width: 100%;
        height: 40px;

        .left {
          width: 50%;
          display: flex;
          align-items: center;
          color: white;
          font-size: 15px;
          margin-left: 11px;
          margin-bottom: 1px;

          span {
            display: inline-block;
            margin-left: 3px;
            font-family: Roboto-Regular;
            font-size: 16px;
          }
        }

        .right {
          display: flex;
          width: 50%;
          height: 100%;
          align-items: center;
          justify-content: flex-end;

          .yellow-box {
            height: 18px;
            width: 18px;
            border-radius: 3px;
            background: #e9ca4e;
            margin-left: 9px;
            margin-right: 9px;
          }

          .red-box {
            height: 18px;
            width: 18px;
            border-radius: 3px;
            background: #b92025;
            margin-right: 9px;
          }

          .ball {
            margin-left: 9px;
            margin-right: 10px;
          }
        }
      }

      .multi-card-body-wrapper {
        display: flex;
        justify-content: space-between;
        background-repeat: round;
        width: 100%;
        height: 70px;
        color: white;
        display: flex;

        .left {
          height: 100%;
          display: flex;
          margin-left: 0;
          margin-top: 3px;

          .star {
            width: 39px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            padding-right: 1px;
            padding-bottom: 3px;
          }

          .teams-wrapper {
            display: flex;
            flex-direction: column;

            .team-1 {
              height: 50%;
              display: flex;
              align-items: center;

            }

            .team-2 {
              height: 50%;
              display: flex;
              align-items: center;
              margin-top: -6px;
              margin-left: 1px;

              .shirt,
              .bg-holder-26 {
                margin-left: 1px;
              }
            }

            .team-1,
            .team-2 {
              .shirt {
                margin-right: 8px;
              }


              .bg-holder-26 {
                // height: 12px;
                // width: 10px;
                // border-radius: 3px;
                // background: rgba(255, 255, 255, 0.3);
                // margin-right: 10px;
                height: 11px;
                width: 10px;
                font-weight: 600;
                border-radius: 3px;
                background: rgba(255, 255, 255, 0.3);
                margin-right: 10px;

                .h-3 {
                  color: #212121;
                  font-family: Roboto, sans-serif;
                  font-size: 10px;
                  letter-spacing: 0;
                  line-height: 11px;
                  text-align: center;
                  text-transform: uppercase;
                  margin-top: -1px;
                }
              }
            }

            .team-1 {
              .shirt {
                margin-right: 7px;

                img {
                  margin-left: 1px;
                  margin-bottom: 1px;
                }
              }

              .bg-holder-26 {
                margin-bottom: 1px;
              }
            }

            .team-2 {
              .shirt {
                margin-right: 7px;
              }
            }
          }
        }

        .middle {
          width: 20%;

          .text {
            text-align: center;
            font-size: 16px;
            font-family: NotoSansCJKkr-Regular;
            margin-top: 2px;
            margin-right: 4px;
            letter-spacing: -1px;
            text-shadow: 0.4px 0.4px 9.6px #ff0006, -0.4px -0.4px 9.6px #ff0006, 0.4px -0.4px 3.6px #ff0006, -0.4px 0.4px 9.6px #ff0006;

            span {
              font-family: Roboto, sans-serif;
              font-size: 16px;
            }
          }

          .button-wrapper {
            width: 100%;
            text-align: center;

            button {
              margin-top: 9px;
              margin-right: 2px;

              .date {
                font-family: Roboto, sans-serif;
                font-size: 12px;
                color: #78b7e5;
              }
            }
          }
        }

        .right {
          padding-right: 0;
          margin-top: 8px;
          margin-right: -2px;

          .line {
            display: flex;
            justify-content: flex-end;
            font-family: RobotoMedium, sans-serif;
            font-size: 18px;
            font-weight: 500;
            // letter-spacing: -2px;
            text-align: end;

            p{
              width: 20px;
              // margin-right: 5px;
            }

            .gold {
              color: #e9ca4e;
              min-width: 15px;
              // width: 17px;
              width: 24px;
              text-align: center;
              margin-right: 0;
              letter-spacing: -2px;
            }

            &:nth-child(2) {
              margin-top: 2px;
            }
          }
        }
      }

    }

    .scroll-wrapper {
      height: 248px;
      overflow-y: scroll;

      .background-image {
        height: 248px;
        // background: url('../../imagesHold/match-bet--small-card.png');
      }

      .background-image-1 {
        height: 250px;
        background: url('../../imagesHold/cards/new/new-bet-card.png');
      }

      // padding-top: 40px;

      // .internal-scroll-wrapper {
      //   background: url('../../imagesHold/match-bet--small-card.png');
      //   height: 248px;
      //   overflow-y: scroll; 
      // }

      .results-content {
        background: transparent;

        // &:nth-child(2) {
        //   .match-bets-content {
        //     .match-bet-card-wrapper {
        //       background: url('../../imagesHold/match-bet--small-card.png');
        //     }
        //   }
        // }
        .match-bets-content {
          background: transparent;

          .match-bet-card-wrapper {
            position: relative;
            height: auto;
            width: 500px;
            max-width: 863px;
            margin-top: 0;
            padding: 0;
            background: transparent;
            // background: url('../../imagesHold/cards/new/new-bet-card.png');
            background-repeat: round;
            // &:nth-child(1) {
            //   background: url('../../imagesHold/match-bet--small-card.png');
            // }

            .line {
              position: absolute;
              top: -1px;
              left: 0;
              width: 100%;
              height: 1px;
              background: linear-gradient(to right, #1d1922 0, #5e2830 30%, #581717 50%, #5f2830 70%, #1d1922 100%);
            }

            .match-details-wrapper {

              .match-details-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-left: 10px;
                height: 50px;

                .left {
                  display: flex;
                  align-items: center;

                  .square {
                    width: 4px;
                    height: 4px;
                    margin-right: 10px;
                  }

                  .text {
                    color: #d1cecf;
                    font-size: 14px;
                  }
                }

                button {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 28px;
                  height: 28px;
                  border-radius: 3px;
                  margin-right: 9px;
                  // background-color: rgb(255, 255, 255);
                  background: url("../../imagesHold/new-button-bg1.png");
                  // opacity: 0.2;

                  .rotate {
                    transform: rotate(180deg);
                  }
                }
              }

              .match-details-content {
                display: flex;
                flex-wrap: wrap;

                .result-row {
                  display: flex;
                  width: 100%;
                  height: 36px;
                }

                .match-results-wrapper {
                  position: relative;
                  display: flex;
                  width: 100%;
                  height: 35px;
                  background-color: rgb(255, 255, 255, 0.102);
                  color: #d1cecf;

                  .left {
                    height: 35px;
                    width: 224px;
                    display: flex;
                    justify-content: space-between;
                    clip-path: polygon(0 0, 94% 0, 99% 100%, 0% 100%);
                    align-items: center;

                    .text {
                      margin-left: 10px;
                      font-size: 14px;
                      font-family: NotoSansCJKkr-Regular;
                    }

                    &:hover {
                      background: linear-gradient(to right, #9b2f30, #9b2f30 25%, #9a2f30 50%, #872d2f 63%, #722a2e 75%, #5d282e);
                    }
                  }

                  .number1 {
                    font-family: RobotoMedium;
                    font-size: 14px;
                    padding-right: 21px;
                    position: absolute;
                    left: 183px;

                    &.red-arrow {
                      .number-value {
                        position: absolute;
                        top: 19px;
                        left: 22px;
                      }
                    }
                  }

                  .red-arrow {
                    background: url("../../imagesHold/cards/red-up-arrow.png");
                    background-repeat: round;
                    width: 71px;
                    height: 57px;
                    margin-right: 1px;
                    left: 152px;
                    top: -11px;
                  }

                  .right {
                    height: 35px;
                    width: 220px;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    clip-path: polygon(5% 0%, 100% 0, 100% 100%, 0% 100%);
                    margin-left: -14px;

                    .text {
                      margin-right: 7.5px;
                      font-size: 14px;
                      font-family: NotoSansCJKkr-Regular;
                    }

                    &:hover {
                      background: linear-gradient(to left, #9b2f30, #9b2f30 25%, #9a2f30 50%, #872d2f 63%, #722a2e 75%, #5d282e);
                    }
                  }

                  .blue-arrow {
                    background: url("../../imagesHold/cards/blue-down-arrow.png");
                    background-repeat: round;
                    width: 71px;
                    height: 57px;
                    left: 279px;
                    top: -11px;
                    margin-right: 2px;
                  }

                  .number2 {
                    font-family: RobotoMedium;
                    font-size: 14px;
                    margin-left: 18px;
                    padding-left: 15px;
                    position: absolute;
                    left: 279px;

                    &.blue-arrow {
                      margin-left: -1px;

                      .number-value {
                        position: absolute;
                        top: 19px;
                        left: 22px;
                      }
                    }
                  }


                  .middle {
                    position: relative;
                    width: 124px;
                    height: 35px;
                    padding: 0 1px;
                    margin-left: -13px;
                    clip-path: polygon(0 0, 100% 0, 94% 100%, 6% 100%);
                    z-index: 2;

                    .left {
                      background: linear-gradient(to top, rgb(255 255 255 / 22%), rgb(255 255 255 / 9%) 50%, rgba(255, 255, 255, 0));
                      width: 1.5px;
                      height: 40px;
                      position: absolute;
                      left: 5.6px;
                      top: -1px;
                      transform: rotate(-16.5deg);
                      z-index: 10;
                    }

                    .right {
                      background: linear-gradient(to top, rgb(255 255 255 / 22%), rgb(255 255 255 / 9%) 50%, rgba(255, 255, 255, 0));
                      width: 1.5px;
                      height: 40px;
                      position: absolute;
                      right: 10.5px;
                      top: -1px;
                      transform: rotate(16.5deg);
                      z-index: 10;
                    }

                    .middle-content {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      width: 76px;
                      height: 35px;
                      padding-left: 0;
                      clip-path: polygon(0 0, 100% 0, 86% 100%, 14% 100%);
                      font-family: RobotoMedium;
                      font-size: 14px;

                      &:hover {
                        background-color: #9b2f30;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

    }


    .bet-info-wrapper {
      position: relative;
      height: 288px;

      .fixed-background-image {
        position: absolute;
        top: 40px;
        left: 0;
        height: 248px;
        width: 100%;
        z-index: -1;
        background: url('../../imagesHold/match-bet--small-card.png');
      }

      .info {
        position: absolute;
        z-index: 15;
        padding-left: 11px;
        width: 99%;
        background: linear-gradient(to right, #2a2a34, #222229 50%, #1a1a20);
        height: 40px;
        display: flex;
        align-items: center;

        .info-image {
          height: 15px;
          width: 15px;
          border-radius: 50%;
          background: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;

          p {
            color: #2a2a34;
            font-family: RobotoBold, sans-serif;
            font-size: 13.31599px;
            line-height: 16px;
            text-align: center;
          }
        }

        .info-content {
          display: flex;
          align-items: center;
          color: #999999;
          font-size: 15px;
          margin-left: 11px;

          .number {
            font-family: Roboto-Regular, sans-serif;

            span {
              color: #ffcc66;
            }
          }

          .text {
            margin-left: 3px;
          }
        }
      }

      .scroll-wrapper {
        height: 288px;

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-thumb {
          background: #666666;
          border-radius: 5px;
        }

        &::-webkit-scrollbar-track {
          background: #333333;
          border-radius: 5px;
        }
      }
    }

    .go-to-game {
      color: #cccccc;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 50px;
      background: linear-gradient(to right, #2a2a34, #22222a 50%, #1a1a20);

      &:hover {
        p {
          text-decoration: underline;
        }
      }

      p {
        margin-left: 5px;
      }
    }
  }

}

.empty-card {
  position: relative;
  background: url('../../imagesHold/empty-card.png');
  background-repeat: round;
  width: 510px;
  height: 490px;
  margin-right: 5px;
  margin-bottom: 5px;

  .content {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    padding-top: 77px;

    p {
      font-size: 13px;
      width: 100%;
      text-align: center;
      color: #717172;
      margin-right: 2px;

      &:nth-child(2) {
        margin-top: -3px;
        margin-right: 2px;
      }
    }
  }
}