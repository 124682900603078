.select-box--container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: white;
  background: linear-gradient(to top, #282828, #1c1c1c 50%, #0f0f0f);
  border: 2px solid #b89c5e;
  border-radius: 6px;
  width: 136px;
  height: 32px;
  margin-left: 9px;
  margin-top: 4px;

}
.select-box--selected-item:hover,
.select-box--selected-item:active {
  border-radius: 4px;
  background: url('../../../imagesHold/fade.png') no-repeat, linear-gradient(to top, #5f4821, #55411e 50%, #49381b);
  cursor: pointer;
  img{
    filter: brightness(4);
  }
}

.select-box--container:hover {
  cursor: pointer;
}

* {
  box-sizing: border-box;
}

.select-box--selected-item {
  display: inline-flex;
  height: 100%;
  width: 100%;
  padding-right: 0;
  vertical-align: middle;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.select-box--selected-item .text{
  text-align: left;
  margin-top: -3px;
  margin-right: 7px;
  margin-left: 4px
}

.select-box--items {
  width: 136px;
  z-index: 28;
  margin-top: 4px;
  margin-right: 0;
  border: 2px solid #b89c5e;
  border-radius: 6px;
  background: linear-gradient(to top, #292929, #0f0f0f);
}

.select-box--items .select-box--item:nth-of-type(1) {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.select-box--items .select-box--item:nth-of-type(3) {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}


.select-box--items div {
  padding: 6px;
  display: flex;
  align-items: center;
  color: white;
  width: 132px;
  height: 32px;
  padding-left: 39px;
}

.select-box--items div:hover {
  background-color: #36363f;
}

.select-box--arrow {
  width: 8px;
  height: 0px;
  margin-top: 10px;
  margin-right: 6px;
  padding: 0;
  display: inline-block;
}

.select-box--arrow-down {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 5px solid transparent;
  border-top: 8px solid #fff;
}

.select-box--arrow-up {
  transform: scaleY(-1);
  margin-top: -9px;
  width: 8px;
}
