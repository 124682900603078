@import 'variables.scss';


@keyframes redAnimation {
  0% {
    left: 19px;
    top: 5px;
  }
  50% {
    left: 19px;
    top: 2px;
  }
  100% {
    left: 19px;
    top: 5px;
  }
}

@keyframes blueDownAnimation {
  0% {
    left: 16px;
    bottom: 5px;
  }
  50% {
    left: 19px;
    bottom: 5px;
  }
  100% {
    left: 16px;
    bottom: 5px;
  }
}

@mixin active-footer {
  // .left {
  //     background: linear-gradient(to right, #9b2f30, #9b2f30 25%, #9a2f30 50%, #872d2f 63%, #722a2e 75%, #5d282e);
  //   }
  // .right {
  //   background: linear-gradient(to left, #9b2f30, #9b2f30 25%, #9a2f30 50%, #872d2f 63%, #722a2e 75%, #5d282e);
  // }
}

.card-footer-wrapper {
  display: flex;
  width: 100%;
  height: 32px;
  background: linear-gradient(to right, #262626, #1c1c1c 25%, #191919 50%, #1c1c1c 75%, #262626 100%);
  color: #d1cecf;

  &.active {
    background: linear-gradient(to right, #2b2425, #333031 25%, #303030 50%, #333031 75%, #2b2425);
    .middle{
      .middle-content {
        background-color: #303030;
        // padding-top: 1px;
      }
    }
  }

  .left {
    height: 32px;
    width: 296px;
    // background-color: green;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .text {
      margin-left: 13px;
      font-size: 14px;
      margin-bottom: 1px;
      font-family: NotoSansCJKkr-Regular;
    }
    .number {
      font-family: Roboto;
      font-size: 14px;
      padding-right: 21px;
      .number-value {
        margin-top: 2px;
        font-family: RobotoMedium, sans-serif;
        margin-right: -4px;
        margin-top: 2px;
        width: 41px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &.red-arrow {
        position: relative;
        z-index: 3;
        img {
          position: absolute;
          left: 19px;
          top: 5px;
          animation: redAnimation 1s infinite;
        }
        .number-value {
          position: absolute;
          margin-left: 2.5px;
          top: 18px;
          left: 10px;
          margin-top: 0;
          width: 46px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .red-arrow {
      background: url("../../../../imagesHold/cards/red-up-arrow.png");
      background-repeat: round;
      width: 71px;
      height: 54px;
      margin-right: 2px;
    }
    &:hover {
      background: linear-gradient(to right, #9b2f30, #9b2f30 25%, #9a2f30 50%, #872d2f 63%, #722a2e 75%, #5d282e);
    }
    &.active-background {
        background: linear-gradient(to right, #9b2f30, #9b2f30 25%, #9a2f30 50%, #872d2f 63%, #722a2e 75%, #5d282e);
    }
  }
  .right {
    height: 32px;
    width: 296px;
    margin-left: -13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .text {
      margin-right: 15px;
      font-size: 14px;
      margin-bottom: 1px;
      font-family: NotoSansCJKkr-Regular;
    }
    .blue-arrow {
      background: url("../../../../imagesHold/cards/blue-down-arrow.png");
      background-repeat: round;
      width: 71px;
      height: 54px;
      margin-right: 2px;
    }
    .number {
      font-family: Roboto;
      font-size: 14px;
      margin-left: 7px;
      padding-left: 15px;
      .number-value {
        font-family: RobotoMedium, sans-serif;
        margin-top: 2px;
        margin-left: -8px;
        width: 46px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &.blue-arrow {
        position: relative;
        margin-left: 1px;
        z-index: 3;
        img {
          position: absolute;
          left: 19px;
          bottom: 5px;
          animation: blueDownAnimation 1s infinite;
        }
        .number-value {
          position: absolute;
          margin-left: 1px;
          top: 16px;
          left: 12px;
          width: 46px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    &:hover {
      background: linear-gradient(to left, #9b2f30, #9b2f30 25%, #9a2f30 50%, #872d2f 63%, #722a2e 75%, #5d282e);
    }
    &.active-background {
      background: linear-gradient(to left, #9b2f30, #9b2f30 25%, #9a2f30 50%, #872d2f 63%, #722a2e 75%, #5d282e);
    }
  }

  .middle {
    width: 94px;
    height: 32px;
    padding: 0 1px;
    margin-left: -14px;
    background: linear-gradient(to top, #494949, #3e3c3c 50%, #191919);
    clip-path: polygon(0 0, 100% 0, 88% 100%, 12% 100%);
    z-index: 2;
    .middle-content {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 92px;
      height: 32px;
      padding-left: 0;
      padding-bottom: 0;
      background-color: #1b1b1b;
      clip-path: polygon(0 0, 100% 0, 88% 100%, 12% 100%);
      font-family: RobotoMedium, sans-serif;
      font-size: 14px;
      padding-top: 2px;
      &:hover,
      &.active {
        background-color: #9b2f30;
      }
    }
  }


  &.active,
  &:hover {
    @include active-footer;
  }

  // &.blue {}
}
