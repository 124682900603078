@import 'variables.scss';

.taps-wrapper {
  margin-top: 10px;
  overflow-x: scroll;

  .taps-button {
    height: 40px;
    padding: 1px;
    font-size: 14px;
    color: #8a8a8d;
    margin-right: 1px;
    background: linear-gradient(to top, #4d4d5b 0, #42424d 50%, #34343e 100%);

    .taps-button-content {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 19px;
      background: linear-gradient(to top, #202026, #24242b 50%, #3d3d48);
    }

    &.active,
    &:hover {
      color: black;
      background: linear-gradient(to top, #fdfcc1 0, #dacead 50%, #c6b389 100%);

      .taps-button-content {
        background: linear-gradient(to top, #d8bc6f, #aa8f60 50%, #d6c8a5);
        font-family: NotoSansCJKkr-Medium;
      }
    }
  }
}